import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router";
import {
  ColumnInfo,
  ColumnVisibility,
  OperationsBrand,
  Sale,
} from "../../types";
import { isRefundableSale } from "../../utils/storeOrderUtils";
import { OrderKeys, ordersTableCols } from "./constants";
import { usdFormatter } from "./usdFormatter";

type TableProps = {
  sales: Sale[];
  displayCols: ColumnVisibility;
};

export const Table = ({ sales, displayCols }: TableProps) => {
  const { orderId } = useParams<{ orderId: any }>();
  const history = useHistory();

  const renderCell = function (colName: string, sale: any) {
    switch (colName) {
      case OrderKeys.ORDER_ID:
        return sale.id;
      case OrderKeys.GUEST_NAME:
        return sale.customer_name;
      case OrderKeys.PROVIDER:
        return sale?.provider?.name;
      case OrderKeys.PHONE_NUMBER:
        return sale.customer_phone ?? sale?.customer?.phone_number;
      case OrderKeys.LOCATION:
        return sale?.location?.name;
      case OrderKeys.BRANDS:
        return (
          <>
            {sale.brands.reduce(
              (accum: string, brand: OperationsBrand, idx: number) => {
                if (idx < 1) return accum + brand.ticker;
                else if (idx < 3) return accum + ", " + brand.ticker;
                else if (idx === 3) return accum + "...";
              },
              "",
            )}
          </>
        );
      case OrderKeys.SUBTOTAL:
        return usdFormatter.format(sale.subtotal / 100);
      case OrderKeys.ORDER_DATE:
        return DateTime.fromMillis(
          parseInt(sale.inserted_at, 10),
        ).toLocaleString(DateTime.DATETIME_MED);
      case OrderKeys.STATUS:
        return sale.status;
      case OrderKeys.REFUND:
        return (
          <>
            {isRefundableSale(sale) ? (
              <a
                onClick={(event: any) => event.stopPropagation()}
                target="_blank"
                rel="noreferrer"
                href={
                  sale.provider.slug === "square"
                    ? `https://app.squareup.com/dashboard/orders/overview/${sale.external_id}`
                    : `https://dashboard.stripe.com/payments/${sale.stripe_payment_intent_id}`
                }
                className="cursor-pointer hover:opacity-80 hover:text-white inline-flex justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-400"
              >
                Refund
              </a>
            ) : (
              ""
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col overflow-hidden overflow-x-scroll">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {ordersTableCols.map((col: ColumnInfo) => {
                    if (displayCols[col.name]) {
                      return (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          key={"th_" + col.name}
                        >
                          {col.headerName}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {sales?.map((sale: Sale, index: number) => (
                  <tr
                    onClick={() => history.push(`orders/${sale.id}`)}
                    className={`cursor-pointer ${
                      index % 2 ? "bg-white" : "bg-gray-50"
                    } hover:bg-green-50`}
                    key={sale.id}
                  >
                    {ordersTableCols.map((col: ColumnInfo) => {
                      if (displayCols[col.name]) {
                        return (
                          <td
                            key={"td_" + col.name}
                            scope="col"
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                          >
                            {renderCell(col.name, sale)}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
