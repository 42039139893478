import { Sale } from "../types";

export function isRefundableSale(sale: Sale) {
  const providerSlug = sale?.provider?.slug;
  const stripePaymentIntentId = sale?.stripe_payment_intent_id;

  const isDirectOrder = [
    "localkitchens",
    "mobile",
    "kiosk",
    "guest-kiosk",
  ].includes(providerSlug);

  return (
    (isDirectOrder && Boolean(stripePaymentIntentId)) ||
    providerSlug === "square"
  );
}
