import { useQueryClient } from "@tanstack/react-query";
import { useListBrandSectionsQuery } from "opsapi/hooks/operations/brandSections";
import { useListCategoriesQuery } from "opsapi/hooks/operations/categories";
import {
  ItemSchema,
  LIST_ITEMS,
  UpdateItemsRequest,
  useListItemsQuery,
  useSyncItemQuery,
  useUpdateItemsQuery,
} from "opsapi/hooks/operations/items";
import { useContext, useEffect, useReducer, useState } from "react";

import { PhotoIcon } from "@heroicons/react/24/solid";
import {
  FormCheckbox,
  FormOption,
  FormSelect,
  Table,
  TableData,
  TableRow,
} from "../../../../ui";
import ToastContext from "../../../../utils/contexts/ToastContext";
import { FlatButton } from "../../_components/FlatButton";

type ItemsChangeset = UpdateItemsRequest["items"];

export const ListItems = () => {
  const queryClient = useQueryClient();
  const { showToast } = useContext(ToastContext);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const toggleIncludeDeleted = () => {
    setIncludeDeleted((prev) => !prev);
  };
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const { data: categories, isLoading: isCategoriesLoading } =
    useListCategoriesQuery();
  const { data: brand_sections, isLoading: isBrandSectionsLoading } =
    useListBrandSectionsQuery();
  const { data: items, isLoading: isItemsLoading } = useListItemsQuery();

  const { mutate: updateItems } = useUpdateItemsQuery({
    reactQuery: {
      onSuccess: () => {
        showToast({
          description: "Items updated successfully",
          variant: "success",
          seconds: 5,
        });
        queryClient.invalidateQueries({
          queryKey: [LIST_ITEMS],
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    },
  });
  const { mutate: syncItems, isPending: isSyncingPending } = useSyncItemQuery({
    reactQuery: {
      onSuccess: (data) => {
        showToast({
          description: `Result: ${JSON.stringify(data)}`,
          variant: "success",
          seconds: 5,
        });
        queryClient.invalidateQueries({
          queryKey: [LIST_ITEMS],
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    },
  });

  const [sortedItems, setSortedItems] = useState<ItemSchema[]>([]);

  useEffect(() => {
    if (items) {
      // create a sorted deep copy of items to track changes
      setSortedItems(
        [
          ...items.sort((a, b) => {
            if (a.category === null && b.category !== null) return -1;
            if (a.category !== null && b.category === null) return 1;
            if (a.brand_section === null && b.brand_section !== null) return -1;
            if (a.brand_section !== null && b.brand_section === null) return 1;
            return 0;
          }),
        ].map((item) => ({ ...item })),
      );
    }
  }, [items]);

  const saveItemUpdates = () => {
    const updates: ItemsChangeset = [];
    sortedItems.forEach((item) => {
      const originalItem = items?.find((i) => i.id === item.id);
      if (item.category?.id !== originalItem?.category?.id) {
        updates.push({
          id: item.id,
          category_id: item.category?.id,
        });
      }
      if (item.is_special_offer !== originalItem?.is_special_offer) {
        updates.push({
          id: item.id,
          is_special_offer: item.is_special_offer,
        });
      }
    });

    if (updates.length === 0) {
      showToast({
        description: "No changes to save",
        variant: "info",
        seconds: 1,
      });
      return;
    }

    updateItems({ items: updates });
  };

  const tableHeaders = [
    "Brand",
    "Name",
    "Legacy & Dietary Tags",
    "Category",
    "Brand Section",
    "Special Offer",
  ];

  if (isItemsLoading || isCategoriesLoading || isBrandSectionsLoading) {
    return <div>Loading...</div>;
  }

  if (!items) {
    return <div>No items found</div>;
  }

  if (!categories) {
    return <div>No categories found</div>;
  }

  if (!brand_sections) {
    return <div>No brand sections found</div>;
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-semibold text-gray-900">Items</h1>
        <div className="flex items-center gap-2">
          <button className="underline" onClick={() => toggleIncludeDeleted()}>
            {includeDeleted ? "Hide" : "Show"} deleted
          </button>
          <FlatButton onClick={() => saveItemUpdates()}>Save Items</FlatButton>
          <FlatButton onClick={() => syncItems({})} loading={isSyncingPending}>
            Sync
          </FlatButton>
        </div>
      </div>

      <Table headers={tableHeaders}>
        {sortedItems.map((item, idx) => (
          <TableRow
            key={item.id}
            rowColor={
              item?.category?.name
                ? idx % 2
                  ? "bg-white"
                  : "bg-gray-50"
                : "bg-yellow-100"
            }
          >
            <TableData>{item.brand?.name || "-"}</TableData>
            <TableData className="flex w-full items-center gap-2 ">
              {item.image_url ? (
                <img
                  src={item.image_url?.replace(".webp", "-320w.webp")}
                  className="object-cover w-8 h-8"
                  alt="item images"
                />
              ) : (
                <PhotoIcon className="w-8 h-8" />
              )}
              {item.name}
            </TableData>
            <TableData>
              {item.category_tags.length > 0
                ? item.category_tags.map((t) => t.name).join(", ")
                : "-"}
              {" + "}
              {item.dietary_tags.length > 0
                ? item.dietary_tags.map((t) => t.name).join(", ")
                : " - "}
            </TableData>
            <TableData>
              <FormSelect
                value={item.category?.id}
                onChange={(e) => {
                  item.category = {
                    id: Number.parseInt(e.target.value),
                    name: "",
                    slug: "",
                  };
                  forceUpdate();
                }}
              >
                <FormOption key={undefined} title="-" value={undefined} />,
                {[
                  categories?.map((category) => (
                    <FormOption
                      key={category.id}
                      title={category.name}
                      value={category.id}
                    />
                  )),
                ]}
              </FormSelect>
            </TableData>
            <TableData>{item.brand_section?.name || "-"}</TableData>
            <TableData>
              <FormCheckbox
                checked={item.is_special_offer}
                onChange={(e) => {
                  item.is_special_offer = e.target.checked;
                  forceUpdate();
                }}
              />
            </TableData>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};
