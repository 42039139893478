import {
  CloudArrowUpIcon,
  CloudIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";
import {
  LocationStateEnum,
  useListLocationsQuery,
} from "opsapi/hooks/operations/locations";
import {
  SyncStateColor,
  useListStorefrontIntegrationsQuery,
} from "opsapi/hooks/operations/storefrontIntegrations";
import {
  StorefrontSchema,
  StorefrontStateEnum,
  StorefrontTypeChoices,
  StorefrontTypeEnum,
  useListStorefrontsQuery,
  useSyncToSquare,
} from "opsapi/hooks/operations/storefronts";
import { useContext, useState } from "react";
import { Button, Table, TableData, TableRow } from "../../../ui/components";
import Loader from "../../../ui/components/Loader/Loader";
import { PageContent } from "../../../ui/components/PageContent/PageContent";
import ToastContext from "../../../utils/contexts/ToastContext";
import { IntegrationSyncDialog } from "../_components/IntegrationSyncDialog";

export const StorefrontsByLocations = () => {
  const { data: locations, isLoading: isLocationsLoading } =
    useListLocationsQuery();
  const { data: storefronts, isLoading: isStorefrontsLoading } =
    useListStorefrontsQuery({});

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [selectedStorefrontType, setSelectedStorefrontType] = useState<
    StorefrontTypeChoices | string | null
  >(null);

  const storefrontIntegrationsParams = {
    params: {
      query: { storefront_type: selectedStorefrontType },
    },
    reactQuery: { enabled: showSyncModal },
  };
  const {
    data: storefrontIntegrations,
    isLoading: isStorefrontIntegrationsLoading,
  } = useListStorefrontIntegrationsQuery(storefrontIntegrationsParams);
  const { showToast } = useContext(ToastContext);
  const { mutate: syncToSquare, isPending: isSyncToSquarePending } =
    useSyncToSquare({
      reactQuery: {
        onSuccess: () => {
          showToast({
            description: "Square Sync Triggered",
            variant: "success",
            seconds: 5,
          });
        },
      },
    });

  if (
    isLocationsLoading ||
    isStorefrontsLoading ||
    !locations ||
    !storefronts
  ) {
    return <Loader loading={true} />;
  }

  const storefrontsByLocationByType: Record<
    string,
    Record<string, StorefrontSchema>
  > = storefronts?.reduce(
    (acc: Record<string, Record<string, StorefrontSchema>>, storefront) => {
      if (!acc[storefront.location.slug]) {
        acc[storefront.location.slug] = {};
      }
      const storefrontType =
        storefront.type === StorefrontTypeEnum.brand
          ? `${storefront.type} ${storefront.focal_brand?.ticker}`
          : storefront.type;
      if (!acc[storefront.location.slug][storefrontType]) {
        acc[storefront.location.slug][storefrontType] = storefront;
      } else {
        throw new Error(
          "Something unexpected happened: duplicate storefront for location+type pair",
        );
      }

      return acc;
    },
    {},
  ) || {};
  locations.forEach((location) => {
    if (!storefrontsByLocationByType[location.slug]) {
      storefrontsByLocationByType[location.slug] = {};
    }
  });

  const brandUniqueTypes =
    storefronts
      ?.filter((storefront) => storefront.type === StorefrontTypeEnum.brand)
      .map(
        (storefront) => `${storefront.type} ${storefront.focal_brand?.ticker}`,
      )
      .filter((name, index, self) => self.indexOf(name) === index)
      .sort() || [];

  const storefrontUniqueTypes = [
    // StorefrontTypeEnum.direct, - to be implemented
    StorefrontTypeEnum.consolidated,
    ...brandUniqueTypes,
  ];

  const tableHeaders = [
    <th key="head" className="text-center w-[90px] sticky left-0 bg-white">
      Location
    </th>,
    ...storefrontUniqueTypes.map((storefrontType) => (
      <th key={storefrontType}>
        <div className="flex items-center justify-center gap-2 p-1 text-center min-w-[90px]">
          <span className="capitalize">{storefrontType}</span>
          <Button
            loading={isStorefrontIntegrationsLoading}
            backgroundColor="bg-blue-500"
            className="text-white font-bold items-center"
            onClick={() => {
              setShowSyncModal(true);
              setSelectedStorefrontType(storefrontType);
            }}
          >
            <CloudArrowUpIcon className="h-5 w-5" />
          </Button>
        </div>
      </th>
    )),
  ];

  return (
    <PageContent>
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold text-gray-900 mr-3 mb-3">
          Storefronts by Type
        </h1>
        <div className="flex gap-3">
          <Button
            loading={isSyncToSquarePending}
            backgroundColor="bg-blue-500"
            className="text-white font-bold items-center gap-2"
            onClick={() => {
              syncToSquare({});
            }}
          >
            <CloudArrowUpIcon className="h-5 w-5" />
            Sync Square
          </Button>
          <Button
            loading={isStorefrontIntegrationsLoading}
            backgroundColor="bg-blue-500"
            className="text-white font-bold items-center gap-2"
            onClick={() => {
              setShowSyncModal(true);
              setSelectedStorefrontType(null);
            }}
          >
            <CloudArrowUpIcon className="h-5 w-5" />
            Sync All
          </Button>
        </div>
      </div>

      <Table headers={tableHeaders}>
        {locations.map((location) => (
          <TableRow
            key={location.id}
            className="border-b-[1px] border-transparent hover:border-b-[1px] hover:border-black hover:bg-opacity-0"
          >
            <TableData className="sticky left-0 bg-white">
              <div className="flex flex-col items-center gap-1">
                <a
                  className="cursor-pointer font-bold"
                  href={`/storefronts/${location.slug}/`}
                >
                  {location.name}
                </a>
                <span className="flex gap-1 text-sm items-center">
                  State:
                  {location.state === LocationStateEnum.active && (
                    <PlayCircleIcon
                      className="h-4 w-4 text-green-500"
                      title={location.state}
                    />
                  )}
                  {location.state === LocationStateEnum.deactivated && (
                    <PauseCircleIcon
                      className="h-4 w-4 text-orange-500"
                      title={location.state}
                    />
                  )}
                </span>
              </div>
            </TableData>

            {storefrontUniqueTypes.map((storefrontType) => {
              const storefront =
                storefrontsByLocationByType[location.slug][storefrontType];
              if (!storefront) {
                return (
                  <TableData key={storefrontType} className="text-center">
                    -
                  </TableData>
                );
              }
              return (
                <TableData
                  key={storefrontType}
                  className={`text-center ${
                    storefront.state === StorefrontStateEnum.active
                      ? SyncStateColor[storefront.worst_sync_state]
                          .replace("text-", "bg-")
                          .replace("500", "200")
                      : ""
                  }`}
                >
                  <div
                    className="flex flex-col w-full h-full items-center gap-1"
                    key={storefront.id}
                  >
                    <a
                      key={storefront.id}
                      className="underline cursor-pointer mr-1 font-bold"
                      href={`/storefronts/${location.slug}/${storefront.slug}/`}
                    >
                      View
                    </a>
                    <span className="flex gap-1 text-sm items-center">
                      State:
                      {storefront.state === StorefrontStateEnum.active && (
                        <PlayCircleIcon
                          className="h-4 w-4 text-green-500"
                          title={storefront.state}
                        />
                      )}
                      {storefront.state === StorefrontStateEnum.deactivated && (
                        <PauseCircleIcon
                          className="h-4 w-4 text-orange-500"
                          title={storefront.state}
                        />
                      )}
                      <CloudIcon
                        className={`h-4 w-4 ${
                          SyncStateColor[storefront.worst_sync_state]
                        }`}
                        title={storefront.worst_sync_state}
                      />
                    </span>
                  </div>
                </TableData>
              );
            })}
          </TableRow>
        ))}
      </Table>

      <IntegrationSyncDialog
        showModal={showSyncModal}
        hideModal={() => setShowSyncModal(false)}
        storefrontName={selectedStorefrontType ?? ""}
        storefronts={storefronts ?? []}
        integrations={storefrontIntegrations ?? []}
      />
    </PageContent>
  );
};
